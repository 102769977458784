import {Acceptance} from 'components/Acceptance'
import {getEmptyPhone} from 'components/inputs/PhoneInput/utils'
import {useSetContactMutation} from 'hooks/mutations/useSetContactMutation'
import {useSettings} from 'providers/AppStateProvider/hooks'
import {
  useCart,
  useOptionalContact,
  useOrderIntentId,
  useOrderIntentSetter,
  useUnitCounters,
} from 'providers/OrderIntentProvider/hooks'
import {useHistoryStepPathOrCurrent, useIsReturnStepVisit} from 'providers/StepHistoryProvider/hooks'
import {useNavigate} from 'react-router-dom'

type Values = {
  emailAddress: string
  phoneNumber: string
  firstName: string
  lastName: string
  confirmedEmailAddress: string
}

type Errors = {
  emailAddress: string
  phoneNumber: string
  firstName: string
  lastName: string
  confirmedEmailAddress: string
}

export const usePageManager = () => {
  const navigate = useNavigate()
  const cartItems = useCart()
  const settings = useSettings()
  const orderIntentId = useOrderIntentId()
  const contact = useOptionalContact()
  const unitCounters = useUnitCounters()
  const setOrderIntent = useOrderIntentSetter()
  const setContactMutation = useSetContactMutation()
  const isReturnStepVisit = useIsReturnStepVisit()
  const penultimateStepPath = useHistoryStepPathOrCurrent(-2)

  const isEdit = Boolean(contact)

  const [cancellationTermsAccepted, toggleCancellationTermsAcceptance] = Acceptance.useManager(isEdit)
  const [chargingTermsAccepted, toggleChargingTermsAcceptance] = Acceptance.useManager(isEdit)
  const [mailingTermsAccepted, toggleMailingTermsAcceptance] = Acceptance.useManager(contact?.allowMarketing || false)

  const validate = (values: Values) => {
    const errors: Partial<Errors> = {}

    const phoneParts = values.phoneNumber?.split('|') || []
    if (phoneParts.length !== 3 || phoneParts[2].trim() === '') {
      errors.phoneNumber = 'Incorrect phone'
    }

    if (!values.emailAddress || !values.emailAddress.includes('@')) {
      errors.emailAddress = 'Incorrect email'
    }

    if (values.confirmedEmailAddress !== values.emailAddress) {
      errors.confirmedEmailAddress = 'Fields do not match'
    }

    if (!values.firstName) {
      errors.firstName = 'First name required'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name required'
    }

    return errors
  }

  const save = (values: Values) => {
    setContactMutation.mutate(
      {
        orderIntentId,
        contact: {
          ...values,
          allowMarketing: mailingTermsAccepted,
        },
      },
      {
        onSuccess({orderIntent}) {
          setOrderIntent(orderIntent)
          isReturnStepVisit ? navigate(penultimateStepPath) : navigate('/steps/travelers')
        },
      }
    )
  }

  const initialValues: Values = contact
    ? {
        firstName: contact.firstName,
        lastName: contact.lastName,
        emailAddress: contact.emailAddress,
        confirmedEmailAddress: contact.emailAddress,
        phoneNumber: contact.phoneNumber,
      }
    : {
        firstName: '',
        lastName: '',
        emailAddress: '',
        confirmedEmailAddress: '',
        phoneNumber: getEmptyPhone(settings.countryCode),
      }

  const data = {
    cartItems,
    unitCounters,
    initialValues,
    cancellationTermsAccepted,
    chargingTermsAccepted,
    mailingTermsAccepted,
  }

  return {
    save,
    validate,
    data,
    toggleCancellationTermsAcceptance,
    toggleChargingTermsAcceptance,
    toggleMailingTermsAcceptance,
    isEdit,
  }
}
